const getCopytradingStrategyName = packageID => {
  const strategyNames = ['powermade1-0', 'powermade2-0', 'powermade3-0'];

  const packageAssociations = [process.env.REACT_APP_COPYTRADING_STRATEGY_1, process.env.REACT_APP_COPYTRADING_STRATEGY_2, process.env.REACT_APP_COPYTRADING_STRATEGY_3];

  const packageAssociationIndex = packageAssociations.findIndex(packageAssociation => {
    return packageAssociation == packageID;
  });

  if (packageAssociationIndex === -1) return;
  return strategyNames[packageAssociationIndex];
};

export default getCopytradingStrategyName;
