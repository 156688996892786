import { checkIfDirectorAccount, checkIfCopytradingPackageBought } from "services/auth/moralis/functions";

const addDeleteDirectorsMenuOption = async (sidebarNavsChildren, authUser, name, icon) => {
  const directorsNavExists = sidebarNavsChildren.some(sidebarNav => sidebarNav.link === '/directors');
  const isDirector = await checkIfDirectorAccount();
  if (isDirector) {
    if (!directorsNavExists) {
      sidebarNavsChildren.push({
        name,
        item: 'item',
        icon,
        link: '/directors',
      });
    }
  } else {
    if (directorsNavExists) {
      const index = sidebarNavsChildren.findIndex(sidebarNav => sidebarNav.link === '/directors');
      sidebarNavsChildren.splice(index, 1);
    }
  }
}

const addDeleteCopytradingMenuOption = async (sidebarNavsChildren, userID, menuName, icon, subMenu1Name, subMenu2Name, subMenu3Name) => {
  const copytradingNavExists = sidebarNavsChildren.some(sidebarNav => sidebarNav.link === '/copytrading');
  const copytradingPackageBought = await checkIfCopytradingPackageBought(userID);
  if (copytradingPackageBought) {
    if (!copytradingNavExists) {
      sidebarNavsChildren.push({
        name: menuName,
        type: 'collapse',
        icon,
        link: '/copytrading',
        children: [
          {
            name: subMenu1Name,
            type: 'item',
            link: '/copytrading/strategy1',
          },
          {
            name: subMenu2Name,
            type: 'item',
            link: '/copytrading/strategy2',
          },
          {
            name: subMenu3Name,
            type: 'item',
            link: '/copytrading/strategy3',
          },
        ],
      });
    } else {
      if (copytradingNavExists) {
        const index = sidebarNavsChildren.findIndex(sidebarNav => sidebarNav.link === '/copytrading');
        sidebarNavsChildren.splice(index, 1);
        addDeleteCopytradingMenuOption(sidebarNavsChildren, authUser, menuName, icon, subMenu1Name, subMenu2Name);
      }
    }
  }
}

export { addDeleteDirectorsMenuOption, addDeleteCopytradingMenuOption }
