import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import EventsPage from './Pages/EventsPage';
import HomePage from './Pages/HomePage';
import CareerPage from './Pages/Career/CareerPage';
import ActivityPoolPage from './Pages/Career/ActivityPoolPage';
import InfinityPoolPage from './Pages/Career/InfinityPoolPage';
import NetworkPage from './Pages/NetworkPage';
import Error404 from './Pages/404';
import ComingSoon from './Pages/ComingSoon';
import CouponUploadPage from './Pages/CouponUploadPage';
import ResourcesPage from './Pages/ResourcesPage';
import DirectorsPage from './Pages/DirectorsPage';
import CopytradingFormPage from './Pages/CopytradingFormPage';
import MemberPage from './Pages/Pack/MemberPage';
import PrimePage from './Pages/Pack/PrimePage';
import PromoterPage from './Pages/Pack/PromoterPage';
import ProfessionalPage from './Pages/Pack/ProfessionalPage';
import PowerFirePage from './Pages/Pack/PowerFirePage';
import MarketPlacePage from './Pages/CryptoMarketPlace/MarketPlacePage';
import GiftCardsPage from './Pages/CryptoMarketPlace/GiftCardsPage';
import AcademyPage from './Pages/Products/AcademyPage';
import CoursesPage from './Pages/Products/CoursesPage';
import EcommercePage from './Pages/Products/EcommercePage';
import CopytradingPage from './Pages/Products/CopytradingPage';
import OtherPage from './Pages/Products/OtherPage';
import NftPage from './Pages/Products/NftPage';
import MetaversePage from './Pages/MetaversePage';
import BuyPwdPage from './Pages/BuyPwdPage';
import LiquidityPage from './Pages/DefiPassive/YieldFarming/LiquidityPage';
import PoolFarmingBnbPage from './Pages/DefiPassive/YieldFarming/PoolFarmingBnbPage';
import PoolStakingPage from './Pages/DefiPassive/PoolStakingPage';
import CelebrationPassPage from './Pages/NftOpenSea/CelebrationPassPage';
import PeopleByVanessiPage from './Pages/NftOpenSea/PeopleByVanessiPage';
import HeroesByChiuciSamaPage from './Pages/NftOpenSea/HeroesByChiuciSamaPage';
import CareerRankPage from './Pages/NftOpenSea/CareerRankPage';
import PurchasesPage from './Pages/PurchasesPage';
import AllPackagesPage from './Pages/Products/AllPackagesPage';
import AdminPage from './Pages/Admin';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import { getQueryParams, getQueryString } from './queryFunctions';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const search = location.search;
  const restrict = ['ref'];
  const queryParams = getQueryParams(search, restrict);

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/signin`,
              search: getQueryString(queryParams),
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const search = location.search;
  const queryParams = getQueryParams(search);
  const restrict = ['ref'];
  const queryParamsWithoutRef = getQueryParams(search, restrict);

  if (location.pathname === '' || location.pathname === '/') {
    return (
      <Redirect
        to={{
          pathname: '/signin',
          search: getQueryString(queryParams),
        }}
      />
    );
  } else if (authUser && location.pathname === '/signin') {
    if (queryParams.loadView) {
      return (
        <Redirect
          to={{
            pathname: `/${queryParams.loadView}`,
            search: getQueryString(queryParamsWithoutRef),
          }}
        />
      );
    } else if (authUser.isAdmin) {
      return <Redirect to={{ pathname: '/admin' }} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: '/home',
            search: getQueryString(queryParamsWithoutRef),
          }}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/home" component={HomePage} />
        <RestrictedRoute path="/career" component={CareerPage} />
        <RestrictedRoute path="/pools/activity" component={ActivityPoolPage} />
        <RestrictedRoute path="/pools/infinity" component={InfinityPoolPage} />
        <RestrictedRoute path="/network" component={NetworkPage} />
        <RestrictedRoute path="/pack/member" component={MemberPage} />
        <RestrictedRoute path="/pack/prime" component={PrimePage} />
        <RestrictedRoute path="/pack/promoter" component={PromoterPage} />
        <RestrictedRoute path="/pack/professional" component={ProfessionalPage} />
        <RestrictedRoute path="/pack/powerfire" component={PowerFirePage} />
        <RestrictedRoute path="/products/academy" component={AcademyPage} />
        <RestrictedRoute path="/products/courses" component={CoursesPage} />
        <RestrictedRoute path="/products/ecommerce" component={EcommercePage} />
        <RestrictedRoute path="/products/copytrading" component={CopytradingPage} />
        <RestrictedRoute path="/products/other" component={OtherPage} />
        <RestrictedRoute path="/products/nft" component={NftPage} />
        <RestrictedRoute path="/packages" component={AllPackagesPage} />
        <RestrictedRoute path="/buy-pwd" component={BuyPwdPage} />
        <RestrictedRoute path="/defi-passive/pool-staking" component={PoolStakingPage} />
        <RestrictedRoute path="/defi-passive/yield-farming/liquidity" component={LiquidityPage} />
        <RestrictedRoute path="/defi-passive/yield-farming/pool-farming-bnb" component={PoolFarmingBnbPage} />
        <RestrictedRoute path="/marketplace/marketplace" component={MarketPlacePage} />
        <RestrictedRoute path="/marketplace/giftcards" component={GiftCardsPage} />
        <RestrictedRoute path="/nft-opensea/celebration-pass" component={CelebrationPassPage} />
        <RestrictedRoute path="/nft-opensea/people-by-vanessi" component={PeopleByVanessiPage} />
        <RestrictedRoute path="/nft-opensea/heroes-by-chiuci-sama" component={HeroesByChiuciSamaPage} />
        <RestrictedRoute path="/nft-opensea/career-rank" component={CareerRankPage} />
        <RestrictedRoute path="/metaverse" component={MetaversePage} />
        <RestrictedRoute path="/events" component={EventsPage} />
        <RestrictedRoute path="/purchases" component={PurchasesPage} />
        <RestrictedRoute path="/resources" component={ResourcesPage} />
        <RestrictedRoute path="/directors" component={DirectorsPage} />
        <RestrictedRoute path="/copytrading/strategy1" component={CopytradingFormPage} />
        <RestrictedRoute path="/copytrading/strategy2" component={CopytradingFormPage} />
        <RestrictedRoute path="/copytrading/strategy3" component={CopytradingFormPage} />
        <RestrictedRoute path="/admin" component={AdminPage} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/coupon-upload" component={CouponUploadPage} />
        <Route path="/*" component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
