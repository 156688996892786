const getCopytradingStrategyLink = strategyName => {
  const links = {
    'powermade1-0': 'https://www.vantagemarkets.co/forex-trading/forex-trading-account/?affid=190309',
    'powermade2-0': 'https://www.vantagemarkets.co/forex-trading/forex-trading-account/?affid=190309',
    'powermade3-0': 'https://www.vantagemarkets.co/forex-trading/forex-trading-account/?affid=190309',
  }

  return links[strategyName];
};

export default getCopytradingStrategyLink;
